const notWeekendPicture = 'sweeties.jpg'

const WEEK_DAYS = [
    { index: 1, name: 'Lunes', shortName: 'L', image: notWeekendPicture },
    { index: 2, name: 'Martes', shortName: 'M', image: notWeekendPicture },
    { index: 3, name: 'Miércoles', shortName: 'X', image: notWeekendPicture },
    { index: 4, name: 'Jueves', shortName: 'J', image: notWeekendPicture },
    { index: 5, name: 'Viernes', shortName: 'V', image: notWeekendPicture },
    { index: 6, name: 'Sábado', shortName: 'S', image: 'saturday.jpeg' },
    { index: 0, name: 'Domingo', shortName: 'D', image: 'weekend.jpg' },
]

function Week() {
    const today = new Date()

    const weekDayItem = ({name, shortName, image}, isHighlighted) => {
        return <>
            <div style={ { textAlign: 'center', margin: '0.5rem', padding: '0.5rem', border: isHighlighted ? '2px solid rgb(52, 152, 219)' : '', borderRadius: '10px' }}>
                {shortName}
                <div style={ { marginTop: '1rem'}}>
                    <img style={ { width: '7rem' } } src={`img/${image}`} alt={name} />
                </div>
            </div>
        </>
    }
    
    return <>
        <div style={ { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {WEEK_DAYS.map((day, i) => weekDayItem(day, day.index === today.getDay()))}
        </div>
    </>
}

export default Week;