import Week from "./Week";
import ColorsGame from "./ColorsGame";
import { useEffect } from "react";
import party from "party-js";

const Private = () => {
    let app;

    useEffect(() => {
        app = document.querySelector('#app');
    })

    const handleClick = () => {
        party.confetti(app, { count: party.variation.range(150, 150) })
    }

    return (
        <>
            <div style={{ margin: '2rem' }}>
                <button onClick={handleClick} style={{ color: 'purple', cursor: 'pointer', background: 'white', fontSize: '2rem' }}>Fiestecilla</button>
            </div>
            <ColorsGame />
            <br />
            <Week />
        </>
    )
};

export default Private;
